





































































































































































































































































































































































































































import VueBase from '../../../VueBase'
import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
@Component({
  name: 'HookTable',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class HookTable extends VueBase {
  @Prop({ default: '0', type: String }) ruleType!: string
  @Prop({ default: 1, type: Number }) activeLanguage!: number
  @Prop({ default: '', type: String }) activeLanguageName!: string
  @Prop({
    default: function () {
      return true
    },
    type: Function,
  })
  getBase!: any

  keyword = ''
  hookTypeDialog = false
  hookDialog = false
  rule_type = ''
  hookType = {
    type: '1',
    name: '',
    short_name: '',
    enable: 1,
    language_id: this.activeLanguage,
  }
  types = []
  hook: any = {
    id: 0,
    type: '1',
    rule_type_id: '',
    rule_value: '',
    source: [{ relation: '', origin: '', param: '' }],
    inherit: 'false',
    track: 'true',
    system_typeA: '',
    system_type: ''
  }
  private stack_blacklist_test: Array<string> = []
  relations = [
    { label: this.$t('views.hookPage.or'), value: '|' },
    { label: this.$t('views.hookPage.and'), value: '&' },
  ]
  origins = [
    { label: this.$t('views.hookPage.O'), value: 'O' },
    { label: this.$t('views.hookPage.R'), value: 'R' },
    { label: this.$t('views.hookPage.P'), value: 'P' },
  ]
  tableData = []
  pageSize = 20
  currentPage = 1
  total = 0
  inputValue = ''
  inputVisible = false

  multipleSelection = []
  handleSelectionChange(val: any) {
    this.multipleSelection = val
  }

  async changeStatusBatch(op: string) {
    let str = ''
    let cancelButtonText = '确认'
    let confirmButtonClass = ''
    switch (op) {
      case 'delete':
        str = this.$t('views.hookPage.del') as string
        cancelButtonText = '删除'
        confirmButtonClass = 'delete-btn'
        break
      case 'enable':
        str = this.$t('views.hookPage.on') as string
        break
      case 'disable':
        str = this.$t('views.hookPage.off') as string
        break
    }
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: cancelButtonText,
      cancelButtonClass: "cancelButtonClass",
      confirmButtonClass: confirmButtonClass,
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          ${this.$t('views.hookPage.changeOne')}${str}${this.$t(
            'views.hookPage.changeTwo'
          )}
        </div>
      `,
    }).then(async () => {
        if (this.multipleSelection.length === 0) {
          this.changeStatusAll(op)
          return
        }
        const ids = this.multipleSelection.map((item: any) => item.id)
        const { status, msg } = await this.services.setting.changeStatusBatch({
          ids: String(ids),
          op,
        })
        if (status !== 201) {
          this.$message.error(op == 'delete' ? '自定义规则删除失败，请重试' : '状态变更失败，请重试')
          return
        }
        this.$message.success(op == 'delete' ? '自定义规则删除成功' : '状态变更成功')
        await this.getBase()
        await this.getTable()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  splitAndIn(str: string, key: string) {
    const arr = str.split(key)
    for (let i = 0; i < arr.length; i++) {
      if (i % 2 != 0) {
        arr.splice(i, 0, key)
        i++
      }
    }
    return arr
  }

  makeParamsArr(arr: any) {
    function fmtParams(params: string, symb: string) {
      const objArr: { relation: string; origin: string; param: string }[] = []
      if (params[0] === 'P') {
        const paramsStr = params.substring(1, params.length)
        const paramsArr = paramsStr.split(',')
        paramsArr.forEach((item) => {
          if (objArr.some((i) => i.origin === 'P')) {
            objArr.push({ relation: '&', origin: 'P', param: item })
          } else {
            objArr.push({ relation: symb, origin: 'P', param: item })
          }
        })
      } else {
        objArr.push({ relation: symb, origin: params, param: '' })
      }
      return objArr
    }
    function fmtParamsArr(arr: any) {
      const source = []
      if (arr.length == 1) {
        source.push(...fmtParams(arr[0], ''))
      } else {
        source.push(...fmtParams(arr[1], arr[0]))
      }
      return source
    }
    const source = []
    const params = []
    let pre = 0
    for (let k = 0; k < arr.length; k++) {
      if (arr[k] == '|' || arr[k] == '&') {
        source.push(arr.slice(pre, k))
        pre = k
      }
      if (k == arr.length - 1) {
        source.push(arr.slice(pre, arr.length))
      }
    }
    for (let i = 0; i < source.length; i++) {
      params.push(...fmtParamsArr(source[i]))
    }
    return params
  }

  async editRow(row: any) {
    const sourceArr = this.splitAndIn(row.source, '|')
    for (let i = 0; i < sourceArr.length; i++) {
      if (sourceArr[i].indexOf('&') > -1) {
        const arr2 = this.splitAndIn(sourceArr[i], '&')
        sourceArr.splice(i, 1, ...arr2)
        i += arr2.length
      }
    }
    const source = this.makeParamsArr(sourceArr)

    this.hook.rule_value = row.value
    this.hook.id = row.id
    this.hook.inherit = row.inherit
    this.hook.source = source
    this.hook.rule_type_id = row.rule_type_id
    this.hook.system_type = row.system_type
    this.stack_blacklist_test = row.stack_blacklist
    this.hookDialog = true
  }

  async getTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.ruleTypes({
      type: this.ruleType,
      language_id: this.activeLanguage,
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.types = data
  }

  addSource(arr: any) {
    arr.push({ relation: '', origin: '', param: '' })
  }
  deleteSource(arr: any, key: number) {
    arr.splice(key, 1)
    arr[0].relation = ''
  }

  changeOrigin(obj: any) {
    if (obj.origin !== 'P') {
      obj.param = ''
    }
  }
  async deleteRule(row: any) {
    this.loadingStart()
    const { status, msg } = await this.services.setting.ruleDelete({
      rule_id: row.id,
    })
    this.loadingDone()
    row.visible = false
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTable()
  }

  async changeStatusAll(status: string) {
    this.loadingStart()
    const obj = await this.services.setting.changeStatus({
      scope: 'all',
      op: status,
      language_id: this.activeLanguage,
      hook_rule_type: this.ruleType,
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message.error(status == 'delete' ? '自定义规则删除失败，请重试' : '状态变更失败，请重试')
      return
    }
    this.$message.success(status == 'delete' ? '自定义规则删除成功' : '状态变更成功')
    this.currentPage = 1
    await this.getBase()
    await this.getTable()
  }

  async changeStatus(row: any, status = '') {
    this.loadingStart()
    const obj = await this.services.setting.changeStatus({
      rule_id: row.id,
      op: status || (row.enable === 0 ? 'disable' : 'enable'),
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message.error(status ? '自定义规则删除失败，请重试' : '状态变更失败，请重试')
      return
    }
    this.$message.success(status ? '自定义规则删除成功' : '状态变更成功')
    await this.getBase()
    await this.getTable()
  }

  clearHookType() {
    this.hookType = {
      type: this.ruleType,
      name: '',
      language_id: this.activeLanguage,
      short_name: '',
      enable: 0,
    }
    this.hookTypeDialog = false
  }
  async enterHookType() {
    this.loadingStart()
    const { status, msg } = await this.services.setting.ruleTypeAdd(
      this.hookType
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTypes()
    this.clearHookType()
  }
  fmtType(type: string) {
    switch (type) {
      case '1':
        return this.$t('views.hookPage.spreadType') as string
      case '2':
        return this.$t('views.hookPage.contaminatedType') as string
      case '3':
        return this.$t('views.hookPage.filterType') as string
      case '4':
        return this.$t('views.hookPage.dangerType') as string
      case '5':
        return this.$t('views.hookPage.enterType') as string
    }
  }
  clearHook() {
    this.stack_blacklist_test = []
    this.hook = {
      id: 0,
      type: this.ruleType,
      rule_type_id: '',
      rule_value: '',
      source: [{ relation: '', origin: '', param: '' }],
      inherit: 'false',
      track: 'true',
      system_type: ''
    }
    this.hookDialog = false
  }
  fmtParams(arr: any) {
    let pStr = ''
    let str = ''
    arr.forEach((item: any) => {
      if (item.origin === 'P' && !pStr) {
        pStr += `${item.relation}${item.origin}${item.param}`
      } else if (item.origin === 'P' && pStr) {
        pStr += `,${item.param}`
      } else {
        str += `${item.relation}${item.origin}`
      }
    })
    return str + pStr
  }
  async enterHook() {
    if (this.hook.id) {
      this.loadingStart()
      const rule_source = this.fmtParams(this.hook.source)
      const { status, msg } = await this.services.setting.modifyAdd({
        rule_id: this.hook.id,
        rule_type_id: this.hook.rule_type_id,
        rule_value: this.hook.rule_value,
        rule_source: rule_source,
        rule_target: '',
        inherit: this.hook.inherit,
        track: this.hook.track,
        language_id: this.activeLanguage,
        stack_blacklist: this.stack_blacklist_test,
      })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      await this.getTable()
      this.clearHook()
    } else {
      this.loadingStart()
      const rule_source = this.fmtParams(this.hook.source)
      const { status, msg } = await this.services.setting.ruleAdd({
        rule_type_id: this.hook.rule_type_id,
        rule_value: this.hook.rule_value,
        rule_source: rule_source,
        rule_target: '',
        inherit: this.hook.inherit,
        track: this.hook.track,
        language_id: this.activeLanguage,
        stack_blacklist: this.stack_blacklist_test,
      })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      await this.getBase()
      await this.getTable()
      this.clearHook()
    }
  }
  async changeRuleType() {
    this.currentPage = 1
    await this.getTable()
  }
  async getTable() {
    this.loadingStart()
    const { status, msg, data, page } =
      await this.services.setting.hookRuleList({
        page: this.currentPage,
        pageSize: this.pageSize,
        type: this.ruleType,
        strategy_type: this.rule_type,
        language_id: this.activeLanguage,
        keyword: this.keyword,
      })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.total = page.alltotal
    if (data.length === 0 && this.currentPage > 1) {
      this.currentPage--
      await this.getTable()
      return
    }
    this.tableData = data
  }
  handleSizeChange(val: number) {
    this.pageSize = val
    this.currentPage = 1
    this.getTable()
  }
  handleCurrentChange(val: number) {
    this.currentPage = val
    this.getTable()
  }
  handleClose(tag: string) {
    let stack_blacklist = this.stack_blacklist_test
    let index = stack_blacklist.indexOf(tag)
    stack_blacklist.splice(index, 1)
  }

  showInput() {
    this.inputVisible = true
    this.$nextTick(() => {
      ;(this.$refs.saveTagInput as any).focus()
    })
  }

  handleInputConfirm() {
    let stack_blacklist = this.stack_blacklist_test
    let inputValue = this.inputValue
    if (inputValue) {
      stack_blacklist.push(inputValue)
    }
    this.inputVisible = false
    this.inputValue = ''
  }
  created() {
    this.hookType.type = this.ruleType
    this.hook.type = this.ruleType
    this.getTable()
    this.getTypes()
  }
}
